<template>
  <div class="content -vertical">
    <div
        class="layout-section"
        data-drag-item
        v-for="(data, index) in sectionData"
        v-bind:key="index"
        data-section-drop
        v-on:drop="onDropSort($event, data.id)"
        v-on:dragenter.prevent="dragEnterStyle($event)"
        v-on:dragover.prevent="dragEnterStyle($event)"
        v-on:dragleave.prevent="dragLeaveStyle($event)"
    >
      <div
          class="layout-section__drag"
          draggable="true"
          v-on:dragstart="dragStartSection($event, data.id)"
      >
        <Icon name="big-drag" />
      </div>
      <div class="layout-section__content">
        <div class="layout-section__name">
          {{data.name}} ({{data.section_alternatives.length}})
        </div>
        <div class="layout-section__body">
          <p class="layout-section__text unformatted-text" v-html="cleanText(data)"></p>
          <div class="layout-section__buttons">
            <button class="button edit-button" @click="deleteLayoutSection(data.id)">
              <Icon name="delete-button" css-class="-edit" />
            </button>
            <router-link class="button edit-button" v-bind:to="`/${customerId}/section/${data.id}`">
              <Icon name="edit-button" css-class="-edit" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content__button">
      <button class="button" @click="createAndAppendNewSection()">
        <Icon name="add-button" css-class="-edit" />
      </button>
    </div>
  </div>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";
import DragDrop from "@/classes/DragDrop";

export default {
  name: 'OrganizerSectionList',
  emits:['drag-sort'],
  mixins: [DragDrop],
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      deletionModalOpen: false,
      deletionId: null,
    };
  },
  props: {
    customerId: {
      type: String,
    },
    dataHandler: {
      type: Object,
    },
    sectionData: {
      type: Array,
    },
    duplicateSections: {
      type: Array,
    },
    draggingSortItem: {
      type: Number,
    },
    layoutId: {
      type: Number
    },
  },
  computed: {
  },
  methods: {
    cleanText(data) {
      return data.section_alternatives[0]?.text_alternatives[0].text.replace(/&nbsp;/g, ' ')
    },
    deleteLayoutSection(sectionId) {
      if (this.duplicateSections.indexOf(sectionId) !== -1) {
        this.dataHandler?.deleteLayoutSection(sectionId, this.layoutId);
      } else {
        this.deletionId = sectionId;
        this.deletionModalOpen = true;
      }
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteLayoutSection(this.deletionId, this.layoutId);
        this.dataHandler?.deleteSection(this.deletionId);
      }
      this.deletionId = null;
      this.deletionModalOpen = false;
    },
    createAndAppendNewSection() {
      this.dataHandler?.createAndAppendNewSection(this.layoutId);
    },
  },
}
</script>

<style lang="scss" scoped>

.layout-section {
  display: flex;
  border-radius: 5px;
  margin-bottom: rem(10px);
  font-size: rem(14px);
  overflow: auto;
  border: 2px solid transparent;

  &.dragover {
    border-color: var(--border-light-blue);
  }

  &__drag {
    width: 20px;
    flex-shrink: 0;
    color: #A9ADB0;
    cursor: pointer;
    background-color: var(--border-light-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: rem(28px);
    }
  }

  &__content {
    width: calc(100% - 20px);
    padding: rem(10px);
    background-color: #F9F9FB;
  }

  &__name {
    font-weight: 500;
    margin-bottom: rem(5px);
  }

  &__body {
    display: flex;
  }

  &__text {
    line-height: 1.9;
    flex-grow: 1;
    margin-right: rem(20px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    button,
    a {
      margin: 0 0 rem(5px) 0;
    }
  }
}

</style>