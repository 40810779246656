<template>
  <div class="content">
    <ul class="content__list">
      <li
          class="content__list-item"
          v-show="!activeFilterToggled || (activeFilterToggled && activeIds.includes(contentData.id))"
          data-layout-drop
          v-for="(contentData, layoutIndex) in routerData"
          v-bind:key="layoutIndex"
          v-on:drop="onDropLayout($event, layoutIndex)"
          v-on:dragenter.prevent="dragEnterStyle($event)"
          v-on:dragover.prevent="dragEnterStyle($event)"
          v-on:dragleave.prevent="dragLeaveStyle($event)"
      >
        <div class="layout" :class="{'-active' : activeIds?.includes(contentData.id)}" data-drag-item>
          <div class="layout__top">
            <div
                class="layout__drag"
                draggable="true"
                v-on:dragstart="dragStartLayout($event, layoutIndex)"
            >
              <div class="layout__flag" v-if="contentData.id === selectedLayout">
                <Icon name="flag" />
              </div>
              <Icon name="drag" css-class="-drag" />
            </div>
            <title-editor
                :value="contentData?.name"
                @edit-title="(value) => { editLayoutName(value, layoutIndex)}"
            />
            <div class="layout__delete">
              <button class="button edit-button" @click="deleteLayout(layoutIndex)">
                <Icon name="delete-button" css-class="-edit" />
              </button>
            </div>
          </div>
          <div
              class="layout__dropzone"
              data-signal-drop
              v-on:drop="onDropSignal($event, layoutIndex)"
              v-on:dragenter.prevent="dragEnterStyle($event)"
              v-on:dragover.prevent="dragEnterStyle($event)"
              v-on:dragleave.prevent="dragLeaveStyle($event)"
          >
            <span
                class="layout__signal"
                v-for="(selector, index) in contentData?.selectors"
                v-bind:key="index"
                v-bind:class="{'not' : selector.startsWith('not.')}"
            >
              <button
                  v-on:click="toggleSignalInLayout(selector, layoutIndex)"
              >
                {{selector}}
              </button>
              <button v-on:click="removeSignalFromLayout(selector, layoutIndex)">
                <Icon name="cancel" />
              </button>
            </span>
          </div>
          <div
              class="layout__content"
              data-section-drop
              v-on:drop="onDropAppendSection($event, contentData.id)"
              v-on:dragenter.prevent="dragEnterStyle($event)"
              v-on:dragover.prevent="dragEnterStyle($event)"
              v-on:dragleave.prevent="dragLeaveStyle($event)"
          >
            <organizer-section-list
                v-bind:section-data="sectionList(contentData.id)"
                v-bind:data-handler="dataHandler"
                v-bind:layout-id="contentData.id"
                v-bind:customer-id="customerId"
                v-on:drag-sort="(value) => { draggingSortItem = value }"
                v-bind:dragging-sort-item="draggingSortItem"
                v-bind:duplicate-sections="duplicateSections"
            />
          </div>
        </div>
      </li>
    </ul>
    <div class="content__button">
      <button class="button" @click="appendNewLayout">
        <Icon name="add-button" css-class="-add" />
      </button>
    </div>
  </div>
  <Modal
      v-bind:show-modal="deletionModalOpen"
      @toggle-modal="confirmDeletion(false)"
      @submit-click="confirmDeletion(true)"
      @cancel-click="confirmDeletion(false)"
      header-text="Bestätigung"
      button-text="Löschen"
      cancel-button-text="Abbrechen"
      v-bind:centered="true"
  >
    <template v-slot:body>
      <div>
        Sind Sie sicher?
      </div>
    </template>
  </Modal>
</template>

<script>
import TitleEditor from "@/components/TitleEditor";
import Icon from "@/components/generic/Icon";
import Modal from "@/components/generic/Modal";
import OrganizerSectionList from '@/components/organizerView/OrganizerSectionList';
import DragDrop from '@/classes/DragDrop';

export default {
  name: 'OrganizerWidget',
  mixins: [DragDrop],
  components: {
    OrganizerSectionList,
    TitleEditor,
    Icon,
    Modal,
  },
  props: {
    dataHandler: {
      type: Object,
    },
    routerData: {
      type: Array,
    },
    activeIds: {
      type: Array,
    },
    customerId: {
      type: String,
    },
    activeFilterToggled : {
      type: Boolean,
    },
  },
  data() {
    return {
      draggingSortItem: null,
      deletionModalOpen: false,
      deletionIndex: null,
    };
  },
  computed: {
    duplicateSections() {
      let arr = [];
      this.routerData.forEach( item => arr.push(...item.section_ids))
      return arr.filter((item, index) => index !== arr.indexOf(item));
    },
    selectedLayout() {
      return this.dataHandler.getSelectedLayout();
    }
  },
  methods: {
    sectionList(id){
      return this.dataHandler?.getSectionsByLayoutId(id);
    },
    editLayoutName(value, index) {
      this.dataHandler?.editLayoutName(value, index);
    },
    deleteLayout(index) {
      this.deletionIndex = index;
      this.deletionModalOpen = true;
    },
    confirmDeletion(response) {
      if (response) {
        this.dataHandler?.deleteLayout(this.deletionIndex);
      }
      this.deletionIndex = null;
      this.deletionModalOpen = false;
    },
    toggleSignalInLayout(signal, layoutIndex) {
      this.dataHandler?.toggleSignalInLayout(signal, layoutIndex);
    },
    removeSignalFromLayout(signal, layoutIndex) {
      this.dataHandler?.removeSignalFromLayout(signal, layoutIndex);
    },
    appendNewLayout() {
      this.dataHandler?.appendNewLayout();
    },
  },
}
</script>

<style lang="scss" scoped>

.layout {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__top {
    width: 100%;
    background-color: var(--bg-light-2);
    color: $text-color;
    padding: rem(6px);
    font-weight: 500;
    border: 2px solid var(--border-light-2);
    border-bottom: none;
    border-radius: rem(5px) rem(5px) 0 0;
    font-size: rem(15px);
    display: flex;
    align-items: center;

    .-active & {
      border: 2px solid var(--bg-dark-2);
      background-color: var(--bg-dark-2);
      color: #ffffff;
    }
  }

  &__dropzone {
    min-height: rem(50px);
    padding: rem(10px);
    border: 2px solid var(--border-light-2);
  }

  &__drag {
    display: flex;
    align-items: center;
    margin-right: rem(10px);
    cursor: pointer;

    svg {
      height: 1.4em;
    }
  }

  &__flag {
    background-color: var(--bg-light-blue);
    height: rem(26px);
    width: rem(26px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 rem(5px) rem(5px) 0;
    margin-left: rem(-8px);
    margin-right: rem(5px);
  }

  &__delete {
    padding-left: rem(10px);
    margin-left: auto;
  }

  &__signal {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: rem(13px);
    background-color: var(--bg-light-blue);
    border-radius: rem(15px);
    padding: rem(7px) rem(10px);
    margin: rem(5px);
    user-select: none;

    &.not {
      background-color: var(--bg-light-red);
    }

    button {
      font-size: rem(13px);
      line-height: 0;

      &:last-of-type {
        margin-left: rem(6px);
      }
    }
  }

  &__signal-icon {
    height: rem(13px);
  }

  &__content {
    padding: rem(13px);
    flex-grow: 1;
    border: 2px solid var(--border-light-2);
    border-top: none;
    border-radius: 0 0 rem(5px) rem(5px);
  }
}
</style>